import HttpService from "./HttpService";
import { Buffer } from "buffer";

const Constants = require("../views/constants/Constants");
const WEB = "Web";

export default class UserService {
    // login
    static login(email, pass, cb, errCb) {
        HttpService.httpCall(
            "POST",
            Constants.API_ROUTES.USER.LOGIN,
            undefined,
            "Basic " + Buffer.from(email + ":" + pass).toString("base64"),
            cb,
            errCb
        );
    }

    // login with facebook
    static loginFb(facebookId, fbToken, email, cb, errCb) {
        HttpService.httpCall(
            "POST",
            Constants.API_ROUTES.USER.LOGIN_FB,
            {
                email: email,
                clientType: WEB,
                facebookId: facebookId,
                facebookToken: fbToken,
            },
            undefined,
            cb,
            errCb
        );
    }

    // login with google
    static loginGoogle(googleId, googleToken, email, cb, errCb) {
        HttpService.httpCall(
            "POST",
            Constants.API_ROUTES.USER.LOGIN_GOOGLE,
            {
                email: email,
                clientType: WEB,
                googleId: googleId,
                googleToken: googleToken,
            },
            undefined,
            cb,
            errCb
        );
    }

    // get all users
    static getAll(
        token,
        pagination,
        sort,
        filter,
        start,
        end,
        state,
        cb,
        errCb
    ) {
        console.log(Constants.API_ROUTES.USER.GET_ALL);
        const path = new URL(Constants.API_ROUTES.USER.GET_ALL);

        Object.keys(pagination).forEach((key) =>
            path.searchParams.append(key, pagination[key])
        );

        path.searchParams.append("sortField", sort.column);
        path.searchParams.append("sortOrder", sort.ascending ? "asc" : "desc");

        if (filter) path.searchParams.append("search", filter);

        // if (state) {
        //   path.searchParams.append(
        //     "state",
        //     state === "Activo" ? "active" : "inactive"
        //   );
        // }

        HttpService.httpCall("GET", path, undefined, token, cb, errCb);
    }

    static block(token, userId, cb, errCb) {
        const body = { userId };
        const path = new URL(Constants.API_ROUTES.USER.BLOCK(userId));
        HttpService.httpCall("PUT", path, body, token, cb, errCb);
    }

    static unBlock(token, userId, cb, errCb) {
        const body = { userId };
        const path = new URL(Constants.API_ROUTES.USER.UNBLOCK(userId));
        HttpService.httpCall("PUT", path, body, token, cb, errCb);
    }

    static makeAdmin(token, userId, cb, errCb) {
        const body = {
            userId,
            role: "Admin",
        };
        const path = new URL(Constants.API_ROUTES.USER.UPDATE_ROLE(userId));
        HttpService.httpCall("PUT", path, body, token, cb, errCb);
    }

    static makeClient(token, userId, cb, errCb) {
        const body = {
            userId,
            role: "Client",
        };
        const path = new URL(Constants.API_ROUTES.USER.UPDATE_ROLE(userId));
        HttpService.httpCall("PUT", path, body, token, cb, errCb);
    }

    // get current user
    static getByToken(token, cb, errCb) {
        HttpService.httpCall(
            "GET",
            Constants.API_ROUTES.USER.GET_TOKEN,
            undefined,
            token,
            cb,
            errCb
        );
    }

    // get user by id
    static getById(token, userId, cb, errCb) {
        HttpService.httpCall(
            "GET",
            Constants.API_ROUTES.USER.GET(userId),
            undefined,
            token,
            cb,
            errCb
        );
    }

    // create user
    static signup(
        token,
        name,
        email,
        password,
        firstName,
        lastName,
        file,
        cb,
        errCb
    ) {
        const formData = new FormData();

        formData.append("email", email);
        formData.append("firstName", firstName);
        formData.append("lastName", lastName);
        formData.append("name", name);
        formData.append("password", password);
        if (file) {
            formData.append("file", file);
        }

        HttpService.httpImageCall(
            "POST",
            Constants.API_ROUTES.USER.SIGNUP,
            formData,
            token,
            cb,
            errCb
        );
    }

    // activate user account
    static activateAccount(userId, code, cb, errCb) {
        HttpService.httpCall(
            "POST",
            Constants.API_ROUTES.USER.ACTIVATE(userId, code),
            undefined,
            undefined,
            cb,
            errCb
        );
    }

    // activate user account while an admin (no code required)
    static activateAccountAdmin(token, userId, cb, errCb) {
        HttpService.httpCall(
            "POST",
            Constants.API_ROUTES.USER.ACTIVATE_ADMIN(userId),
            undefined,
            token,
            cb,
            errCb
        );
    }

    // change language
    static editLanguage(token, language, cb, errCb) {
        HttpService.httpCall(
            "PUT",
            Constants.API_ROUTES.USER.EDIT_LANGUAGE,
            {
                language: language,
            },
            token,
            cb,
            errCb
        );
    }

    // send password recovery email
    static sendPasswordRecoveryEmail(/*name,*/ email, cb, errCb) {
        HttpService.httpCall(
            "POST",
            Constants.API_ROUTES.USER.SEND_PASSWORD_RECOVERY_EMAIL,
            {
                // name: name,
                email: email,
            },
            undefined,
            cb,
            errCb
        );
    }

    // change password
    static editPassword(token, oldPassword, password, cb, errCb) {
        HttpService.httpCall(
            "POST",
            Constants.API_ROUTES.USER.EDIT_PASSWORD,
            {
                oldPassword: oldPassword,
                password: password,
            },
            token,
            cb,
            errCb
        );
    }

    // recover password
    static recoverPassword(userId, password, code, cb, errCb) {
        HttpService.httpCall(
            "POST",
            Constants.API_ROUTES.USER.RECOVER_PASSWORD(userId, code),
            {
                password: password,
            },
            undefined,
            cb,
            errCb
        );
    }

    // block user
    static blockUser(token, userId, cb, errCb) {
        HttpService.httpCall(
            "POST",
            Constants.API_ROUTES.USER.BLOCK(userId),
            undefined,
            token,
            cb,
            errCb
        );
    }

    // block user
    static unBlockUser(token, userId, cb, errCb) {
        HttpService.httpCall(
            "POST",
            Constants.API_ROUTES.USER.UNBLOCK(userId),
            undefined,
            token,
            cb,
            errCb
        );
    }

    // edit user
    static edit(token, userId, params, file, cb, errCb) {
        const formData = new FormData();

        if (params.email) {
            formData.append("email", params.email);
        }
        if (params.name) {
            formData.append("name", params.name);
        }
        if (params.role) {
            formData.append(
                "role",
                params.role === "Administrador" ? "Admin" : "Client"
            );
        }
        if (params.password) {
            formData.append("password", params.password);
        }
        if (file) {
            formData.append("file", file);
        }

        HttpService.httpImageCall(
            "PUT",
            Constants.API_ROUTES.USER.EDIT(userId),
            formData,
            token,
            cb,
            errCb
        );
    }

    // delete user
    static delete(token, userId, cb, errCb) {
        HttpService.httpCall(
            "DELETE",
            Constants.API_ROUTES.USER.DELETE(userId),
            undefined,
            token,
            cb,
            errCb
        );
    }
}
