import SuccessToast from "../views/utils/toasts/SuccessToast";
import CookieService from "./CookieService";
import LocalStorageService from "./LocalStorageService";
const Constants = require("../views/constants/Constants");

export default class HttpService {
    static httpCall(method, url, body, token, cb, errCb, noRetry) {
        const data = {
            method: method,
            headers: {},
        };

        if (token) {
            data.headers["Authorization"] = token;
            data.headers["x-token"] = token;
        }

        if (body) {
            data.headers["Content-Type"] = "application/json";
            data.body = JSON.stringify(body);
        }

        let statusCode;
        let respToken;
        fetch(url, data)
            .then((data) => {
                // Not working
                for (var pair of data.headers.entries()) {
                    if (pair[0] === "authorization") {
                        respToken = pair[1];
                    }
                }

                statusCode = data.status;
                return data.json();
            })
            .then((data) => {
                console.log("res", data);
                let statusMessage = data.status;
                if (statusMessage == "success") {
                    respToken = data.token;
                    if (respToken) {
                        LocalStorageService.set("token", respToken);
                    }

                    return cb({ data: data.data });
                } else {
                    if (data.error.includes("Token no válido")) {
                        LocalStorageService.delete("token");
                    }
                    return cb({
                        errorMessage: data.error,
                        errorCode: statusCode,
                    });
                }
            })
            .catch((err) => {
                if (err.errorCode === 401) LocalStorageService.delete("token");
                // LocalStorageService.delete("token");
                console.log(err);
                if (!err.errorMessage)
                    err = { errorMessage: err.message, errorCode: 404 };
                return errCb(err);
            });
    }

    static async httpCallAsync(method, url, body, token) {
        const data = {
            method: method,
            headers: {},
        };

        if (token) {
            data.headers["Authorization"] = token;
            data.headers["x-token"] = token;
        }

        if (body) {
            data.headers["Content-Type"] = "application/json";
            data.body = JSON.stringify(body);
        }

        let statusCode;
        let respToken;
        return await fetch(url, data)
            .then((data) => {
                // Not working
                for (var pair of data.headers.entries()) {
                    if (pair[0] === "authorization") {
                        respToken = pair[1];
                    }
                }

                statusCode = data.status;
                return data.json();
            })
            .then((data) => {
                console.log("res", data);
                let statusMessage = data.status;
                if (statusMessage == "success") {
                    respToken = data.token;
                    if (respToken) {
                        LocalStorageService.set("token", respToken);
                    }

                    return { data: data.data };
                } else {
                    return {
                        errorMessage: data.error,
                        errorCode: statusCode,
                    };
                }
            })
            .catch((err) => {
                if (err.errorCode === 401) LocalStorageService.delete("token");
                // LocalStorageService.delete("token");
                console.log(err);
                if (!err.errorMessage)
                    err = { errorMessage: err.message, errorCode: 404 };
                return err;
            });
    }

    static httpCallWithImageResponse(method, url, body, token, cb, errCb) {
        const data = {
            method: method,
            headers: {},
        };

        if (token) {
            data.headers["Authorization"] = token;
            data.headers["x-token"] = token;
        }

        if (body) {
            data.headers["Content-Type"] = "application/json";
            data.body = JSON.stringify(body);
        }

        let statusCode;
        let respToken;
        fetch(url, data)
            .then((data) => {
                statusCode = data.status;
                console.log("res", data);
                return data.formData();
            })
            .then((data) => {
                const dataObj = JSON.parse(data.get("data"));
                const file = data.get("file");
                console.log("res json", dataObj);
                console.log("res file", file);
                let statusMessage = data.status;
                if (statusMessage == "success") {
                    respToken = data.token;
                    if (respToken) {
                        LocalStorageService.set("token", respToken);
                    }
                    return cb({ data: data.data, file: file });
                } else {
                    return cb({
                        errorMessage: data.error,
                        errorCode: statusCode,
                    });
                }
            })
            .catch((err) => {
                // LocalStorageService.delete("token");
                console.log(err);
                if (!err.errorMessage)
                    err = { errorMessage: err.message, errorCode: 404 };
                return errCb(err);
            });
    }

    static httpImageCall(method, url, formData, token, cb, errCb) {
        const headers = {};

        if (token) {
            headers.Authorization = token;
            headers["x-token"] = token;
        }

        // Note: We're not setting the Content-Type header here since Multer will
        // automatically set it to multipart/form-data when we send the FormData object.

        let statusCode;
        let respToken;
        fetch(url, {
            method: method,
            headers: headers,
            body: formData,
        })
            .then((response) => {
                statusCode = response.status;
                const contentType = response.headers.get("Content-Type");

                console.log("Content type", contentType);

                // Check if the response is an image
                if (contentType && contentType.startsWith("image/")) {
                    // Read the response as a binary blob
                    return response.blob();
                } else {
                    // Read the response as JSON data
                    return response.json();
                }
            })
            .then((data) => {
                console.log("res", data);

                // Check if the response is an image
                if (data instanceof Blob) {
                    // Create an image URL from the binary blob
                    const imageUrl = URL.createObjectURL(data);

                    return cb({ imageUrl: imageUrl });
                } else {
                    let statusMessage = data.status;
                    if (statusMessage == "success") {
                        respToken = data.token;
                        if (respToken) {
                            LocalStorageService.set("token", respToken);
                        }
                        return cb({ data: data.data });
                    } else {
                        return cb({
                            errorMessage: data.error,
                            errorCode: statusCode,
                        });
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                if (!err.errorMessage)
                    err = { errorMessage: err.message, errorCode: 404 };
                return errCb(err);
            });
    }

    static refreshToken(cb, errCb) {
        const refreshToken = LocalStorageService.get("refreshToken");
        HttpService.httpCall(
            "POST",
            Constants.API_ROUTES.USER.REFRESH_TOKEN,
            undefined,
            refreshToken,
            cb,
            errCb,
            true
        );
    }
}
