import { toast } from "react-toastify";
const Constants = require("../../constants/Constants");

export default class SuccessToast {
    static notify(message, history, path, autoClose) {
        toast.success(message, {
            position: "top-center",
            autoClose: autoClose || 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onClose: () => {
                if (!!path) {
                    history.push({
                        pathname: path,
                    });
                }
            },
        });
    }

    static notifyDelete(message, history, path) {
        toast.success(message, {
            position: "top-center",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onClose: () => {
                if (!!path) {
                    history.push({
                        pathname: path,
                    });
                }
            },
        });
    }
}
