import I18n from "./../utils/I18n";

const errors = {
    LOGIN: {
        NO_ADMIN: { errorCode: 403, errorMessage: I18n.t("LOGIN.NO_ADMIN") },
        NO_ACTIVE: { errorCode: 403, errorMessage: I18n.t("LOGIN.NO_ACTIVE") },
    },
    EDIT: {
        MISSING: {
            NAME: {
                errorCode: 422,
                errorMessage: I18n.t("ERRORS.NAME_MISSING"),
            },
            LAST_NAME: {
                errorCode: 422,
                errorMessage: I18n.t("ERRORS.LAST_NAME_MISSING"),
            },
            USERNAME: {
                errorCode: 422,
                errorMessage: I18n.t("ERRORS.USERNAME_MISSING"),
            },
            PASSWORD: {
                errorCode: 422,
                errorMessage: I18n.t("ERRORS.PASSWORD_MISSING"),
            },
        },
        INVALID: {
            EMAIL: {
                errorCode: 422,
                errorMessage: I18n.t("ERRORS.INVALID_EMAIL"),
            },
            PASSWORD: {
                errorCode: 422,
                errorMessage: I18n.t("ERRORS.INVALID_PASSWORD"),
            },
        },
    },
    CREATE_WORD_LIST: {
        MISSING: {
            NAME: {
                errorCode: 422,
                errorMessage: I18n.t("ERRORS.NAME_MISSING"),
            },
            LANGUAGE: {
                errorCode: 422,
                errorMessage: I18n.t("ERRORS.LANGUAGE_MISSING"),
            },
            DESCRIPTION: {
                errorCode: 422,
                errorMessage: I18n.t("ERRORS.DESCRIPTION_MISSING"),
            },
            TYPE: {
                errorCode: 422,
                errorMessage: I18n.t("ERRORS.TYPE_MISSING"),
            },
            FILE_CODE: {
                errorCode: 422,
                errorMessage: I18n.t("ERRORS.CODE_MISSING"),
            },
            FILE: {
                errorCode: 422,
                errorMessage: I18n.t("ERRORS.FILE_MISSING"),
            },
        },
        INVALID: {
            CODE: { errorCode: 422, errorMessage: I18n.t("ERRORS.CODE_USED") },
            CODE_SPECIAL: {
                errorCode: 422,
                errorMessage: I18n.t("ERRORS.CODE_SPECIAL"),
            },
            TYPE: {
                errorCode: 422,
                errorMessage: I18n.t("ERRORS.INVALID_TYPE"),
            },
        },
    },
    CREATE_NOTE: {
        MISSING: {
            TITLE: {
                errorCode: 422,
                errorMessage: I18n.t("ERRORS.TITLE_MISSING"),
            },
            SHORT_DESCRIPTION: {
                errorCode: 422,
                errorMessage: I18n.t("ERRORS.SHORT_DESCRIPTION_MISSING"),
            },
            SLUG: {
                errorCode: 422,
                errorMessage: I18n.t("ERRORS.SLUG_MISSING"),
            },
            CONTENT: {
                errorCode: 422,
                errorMessage: I18n.t("ERRORS.CONTENT_MISSING"),
            },
            EXTERNAL_URL: {
                errorCode: 422,
                errorMessage: I18n.t("ERRORS.EXTERNAL_URL_MISSING"),
            },
        },
        INVALID: {
            IMAGE_SIZE: {
                errorCode: 422,
                errorMessage: I18n.t("ERRORS.IMAGE_SIZE"),
            },
            SLUG: {
                errorCode: 401,
                errorMessage: I18n.t("ERRORS.SLUG_USED"),
            },
        },
    },
    EDIT_NOTE: {
        MISSING: {
            TITLE: {
                errorCode: 422,
                errorMessage: I18n.t("ERRORS.TITLE_MISSING"),
            },
            SHORT_DESCRIPTION: {
                errorCode: 422,
                errorMessage: I18n.t("ERRORS.SHORT_DESCRIPTION_MISSING"),
            },
            SLUG: {
                errorCode: 422,
                errorMessage: I18n.t("ERRORS.SLUG_MISSING"),
            },
            CONTENT: {
                errorCode: 422,
                errorMessage: I18n.t("ERRORS.CONTENT_MISSING"),
            },
            EXTERNAL_URL: {
                errorCode: 422,
                errorMessage: I18n.t("ERRORS.EXTERNAL_URL_MISSING"),
            },
        },
        INVALID: {
            IMAGE_SIZE: {
                errorCode: 422,
                errorMessage: I18n.t("ERRORS.IMAGE_SIZE"),
            },
        },
    },
    CREATE_CONTENT: {
        MISSING: {
            TITLE: {
                errorCode: 422,
                errorMessage: I18n.t("ERRORS.TITLE_MISSING"),
            },
            DESCRIPTION: {
                errorCode: 422,
                errorMessage: I18n.t("ERRORS.DESCRIPTION_MISSING"),
            },
            TYPE: {
                errorCode: 422,
                errorMessage: I18n.t("ERRORS.TYPE_MISSING"),
            },
            FILE_CODE: {
                errorCode: 422,
                errorMessage: I18n.t("ERRORS.CODE_MISSING"),
            },
            FILE: {
                errorCode: 422,
                errorMessage: I18n.t("ERRORS.FILE_MISSING"),
            },
        },
        INVALID: {
            CODE: { errorCode: 422, errorMessage: I18n.t("ERRORS.CODE_USED") },
            CODE_SPECIAL: {
                errorCode: 422,
                errorMessage: I18n.t("ERRORS.CODE_SPECIAL"),
            },
            TYPE: {
                errorCode: 422,
                errorMessage: I18n.t("ERRORS.INVALID_TYPE"),
            },
        },
    },
    DEBT: {
        INVALID: {
            TYPE: {
                errorCode: 422,
                errorMessage: I18n.t("ERRORS.INVALID_DATA_FORMAT"),
            },
        },
    },
    GENERIC: {
        SERVER_ERROR: {
            errorCode: 500,
            errorMessage: I18n.t("ERRORS.SERVER_ERROR"),
        },
    },
};

export default errors;
