const API_URL =
    window._env_ && window._env_.REACT_APP_BACKEND
        ? window._env_.REACT_APP_BACKEND
        : process.env.REACT_APP_BACKEND;

const FILES_URL =
    window._env_ && window._env_.REACT_APP_FILES
        ? window._env_.REACT_APP_FILES
        : process.env.REACT_APP_FILES;

module.exports = {
    API_ROUTES: {
        STATISTICS: {
            GET_GAMES: `${API_URL}/statistics/games`,
            GET_WORD_LIST: `${API_URL}/statistics/popular-word-lists`,
            GET_TOTAL_USERS: `${API_URL}/statistics/user-by-day`,
        },
        IMAGES: {
            GET_ALL: `${API_URL}/images`,
            UPLOAD_IMAGE: `${API_URL}/images`,
            UPDATE_SLUG: `${API_URL}/images`,
            DELETE_MEDIA: (slug) => `${API_URL}/images/${slug}`,
            GET_MEDIA: (slug) => `${API_URL}/images/slug/${slug}`,
        },
        NOTES: {
            GET_ALL: `${API_URL}/notes`,
            CREATE_NOTE: `${API_URL}/notes`,
            GET: (id) => `${API_URL}/notes/${id}`,
            GET_TITLES: `${API_URL}/notes/titles`,
        },
        USER: {
            GET_AVATAR_URL: (resource) => `${API_URL}/resource/${resource}`,
            GET_ALL: `${API_URL}/user/all`,
            GET_TOKEN: `${API_URL}/user/`,
            GET: (userId) => `${API_URL}/user/${userId}`,
            BLOCK: (userId) => `${API_URL}/user/${userId}/block`,
            UNBLOCK: (userId) => `${API_URL}/user/${userId}/unblock`,
            UPDATE_ROLE: (userId) => `${API_URL}/user/${userId}/role`,
            LOGIN: `${API_URL}/auth/login`,
            LOGIN_FB: `${API_URL}/user/login/fb`,
            LOGIN_GOOGLE: `${API_URL}/user/login/google`,
            REFRESH_TOKEN: `${API_URL}/user/refreshToken`,
            SIGNUP: `${API_URL}/user/signup/`,
            ACTIVATE: (userId, code) =>
                `${API_URL}/user/${userId}/activate/${code}`,
            ACTIVATE_ADMIN: (userId) => `${API_URL}/user/${userId}/activate/`,
            MAKE_ADMIN: (userId) => `${API_URL}/user/${userId}/admin/`,
            EDIT: (userId) => `${API_URL}/user/${userId}`,
            EDIT_LANGUAGE: `${API_URL}/user/language`,
            SEND_PASSWORD_RECOVERY_EMAIL: `${API_URL}/user/password/forgot`,
            RECOVER_PASSWORD: (userId, code) =>
                `${API_URL}/user/${userId}/password/recover/${code}`,
            EDIT_PASSWORD: `${API_URL}/user/password`,
            DELETE: (userId) => `${API_URL}/user/${userId}`,
            GET_STATS: `${API_URL}/user/number`,
        },
        LANGUAGE: {
            GET_ALL: `${API_URL}/language`,
            GET_ALL_COMPLETE: `${API_URL}/language/complete`,
            DELETE: (wordListId) => `${API_URL}/language/${wordListId}`,
            RESTORE: (wordListId) =>
                `${API_URL}/language/${wordListId}/restore`,
            ADD_LANGUAGE: `${API_URL}/language`,
        },
        WORD_LIST: {
            GET_ALL: `${API_URL}/word-list/all`,
            CREATE: `${API_URL}/word-list`,
            GET: (wordListId) => `${API_URL}/word-list/${wordListId}`,
            EDIT: (wordListId) => `${API_URL}/word-list/${wordListId}`,
            DELETE: (wordListId) => `${API_URL}/word-list/${wordListId}`,
            RESTORE: (wordListId) =>
                `${API_URL}/word-list/${wordListId}/restore`,
        },
        WORD: {
            ADD_WORDS: `${API_URL}/word`,
            GET: (wordListId) => `${API_URL}/word/all/${wordListId}`,
            DELETE: (wordId) => `${API_URL}/word/${wordId}`,
            EDIT_WORDS: `${API_URL}/word/bulk`,
        },
        REPORTS: {
            GET_ALL: `${API_URL}/report`,
            CREATE: `${API_URL}/report`,
            VALIDATE: `${API_URL}/report/validate`,
            GET: (reportId) => `${API_URL}/report/${reportId}`,
            DISCARD: (reportId) => `${API_URL}/report/discard/${reportId}`,
            FILE: (fileCode) => `${FILES_URL}/${fileCode}`,
            EDIT: (contentId) => `${API_URL}/report/${contentId}`,
        },
        DEBT: {
            GET_DRAFT_GRAPHS: (frecuency) =>
                `${API_URL}/debtExpiration/draft/all/${frecuency}`,
            GET_DRAFT_TABLE: `${API_URL}/debtExpiration/draft/all`,
            GET_DRAFT_ASSETS: `${API_URL}/debtExpiration/draft/activesAndAssetClasses`,
            UPLOAD_DRAFT: `${API_URL}/debtExpiration/draft`,
            UPLOAD_LIVE: `${API_URL}/debtExpiration/live`,
        },
        FEED: {
            GET_ALL: `${API_URL}/publication/all`,
            CREATE: `${API_URL}/publication`,
            FILE: (fileCode) => `${API_URL}/publication/file/${fileCode}`,
            DELETE: (id) => `${API_URL}/publication/${id}`,
        },
    },
    ROUTES: {
        ACCOUNT_ACTIVATION: "/activateAccount",
        PASSWORD_RECOVER: "/passwordRecovery",
        FORGOT: "/forgot",
        LOGIN: "/",
        LEAGUES: "/leagues",
        FEEDBACK: "/feedback",
        GAMES: "/games",
        GAME: (gameId) => "/game/" + gameId,
        TEAMS: (leagueId) => "/teams/" + leagueId,
        USER: (userId) => "/user/" + userId,
        REPORTED: "/report",

        USERS: "/users",
        DASHBOARD: "/dashboard",
        WORDLIST: "/word-list",
        EDIT_USER: (userId) => "/users/" + userId,
        CREATE_USER: "/users/createUser",
        REPORTS: "/reports",
        NOTES: "/notes",
        MEDIA: "/media",
        CREATE_NOTE: "/notes/create",
        UPLOAD_MEDIA: "/media/upload",
        EDIT_NOTE: (noteId) => "/notes/" + noteId,
        EDIT_REPORT: (reportId) => "/reports/" + reportId,
        EDIT_CONTENT: (contentId) => "/contents/" + contentId,
        CREATE_CONTENT: "/contents/createContent",
        LANGUAGE: "/languages",
        FEED: "/feed",
    },

    PAGINATION: {
        PAGE_SIZES: [10, 20, 50, 100],
    },

    USER_TYPES: {
        Admin: "Admin",
        Client: "Client",
    },

    TYPES: {
        TEXT: "Text",
        PARAGRAPH: "Paragraph",
        PASSWORD: "Password",
        DATE: "Date",
        EMAIL: "Email",
        PHONE: "Phone",
        NUMBER: "Number",
        BOOLEAN: "Boolean",
        CHECKBOX: "Checkbox",
    },

    TITLE_MAX_LEN: 150,
    CONTENT_MAX_LEN: 10000,
    SLUG_MAX_LEN: 100,
    SHORT_DESCRIPTION_MAX_LEN: 500,

    DEFAULT_LANGUAGE: "es",
    AVAILABLE_LANGUAGES: ["en", "es"],
};
